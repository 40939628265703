button {
  border: 1px solid #f88932;
  color: #f88932;
  font-size: 18px;
  font-weight: 600;
  margin: 2px;
}
.div-border {
  background: #e1773845;
  font-size: 18px;
  border-radius: 10px;
  margin: 5px;
}
.style-border {
  border: 0px;
  margin: 10px;
}
.icon-style {
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
}
li {
  list-style: none;
}
