.container-fluid {
  display: flex;
  padding: 0%;
  background-color: #fbfbfb;
  justify-content: center;
}
.welcome {
  padding-left: 6%;
  padding-top: 40px;
  padding-bottom: 24px;
  margin-bottom: 0px;
  background-color: #fbfbfb;
}

.link {
  text-decoration: none;
  color: inherit;
}

.success-msg {
  color: #0f5132;
  text-align: center;
  margin-top: 120px;
}

.success-msg svg {
  font-size: 60px;
  border: 1px solid #0f5132;
  border-radius: 30px;
  padding: 10px;
}
.success-msg .title {
  font-size: 25px;
  margin-top: 10px;
}

.hv-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  /* background-color: #f7f7f7; */
  padding: 0px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.initials-circle {
  font-size: 1.4em;
  border: 3px solid gray;
  font-size: 12px;
  font-weight: 500;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
}
.ImageLogo {
  width: 150px;
  height: 28px;
}

.form-control {
  border: none;
  background-color: #f4f4f5;
  height: 48px;
  padding: 12px;
}
textarea {
  border-color: #f4f4f5;
  background-color: #f4f4f5;
  border-radius: 4px;
  width: 312px;
  height: 152px;
}
textarea:focus {
  border-color: #f4f4f5;
  background-color: #f4f4f5;
}
