.invoice-title {
  display: flex;
  justify-content: space-between;
}
.invoice-title h2,
.invoice-title h3 {
  display: inline-block;
}

.table > tbody > tr > .no-line {
  border-top: none;
}

.table > thead > tr > .no-line {
  border-bottom: none;
}

.table > tbody > tr > .thick-line {
  border-top: 2px solid;
}
.text {
  text-align: center;
}
.address {
  display: flex;
}
.detail-div {
  display: grid;
}
.table,
.th,
.td {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
}
.container {
  padding: 50px;
}
